import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { Grid, Typography, FormHelperText, Box } from "@mui/material";
import { hexToNum, numToHex } from "../../Controls/utilsColors";
import { defaultColorRange } from "../representationParamOptions";
import { InputNumberFocusOut } from "./InputNumberFocusOut";
import { ColorRangeElements } from "./ColorRangeElements";
import { ParamOptionsMenu } from "../../Controls/ParamOptionsMenu";
function SelectColorRagePopoverContent({ bfactorColorRange, bfactorColors, callback, }) {
    const _rangeMin = bfactorColorRange && bfactorColorRange.length > 0
        ? bfactorColorRange[0]
        : 0;
    const _rangeMax = bfactorColorRange && bfactorColorRange.length > 1
        ? bfactorColorRange[1]
        : 100;
    const _colors = bfactorColors && bfactorColors.length > 0
        ? bfactorColors
        : defaultColorRange;
    const _colorsHex = _colors.map((c) => numToHex(c));
    const [rangeMin, setRangeMin] = useState(_rangeMin);
    const [rangeMax, setRangeMax] = useState(_rangeMax);
    const [colors, setColors] = useState(_colorsHex);
    useEffect(() => {
        setRangeMin(_rangeMin);
    }, [_rangeMin]);
    useEffect(() => {
        setRangeMax(_rangeMax);
    }, [_rangeMax]);
    useEffect(() => {
        setColors(_colorsHex);
    }, [JSON.stringify(_colorsHex)]);
    useEffect(() => {
        const colorsNum = colors.map((c) => hexToNum(c));
        const newParams = {
            bfactor_color_range: [rangeMin, rangeMax],
            bfactor_colors: colorsNum,
        };
        callback(newParams);
    }, [rangeMin, rangeMax, JSON.stringify(colors)]);
    return (_jsxs(Grid, Object.assign({ container: true, direction: "column", justifyContent: "center", alignItems: "flex-start" }, { children: [_jsxs(Grid, Object.assign({ container: true, item: true, xs: true, 
                // columnSpacing={1}
                direction: "row", alignItems: "center", justifyContent: "flex-start" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsxs(Box, Object.assign({ sx: { display: "flex", gap: 1 }, alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ sx: { fontSize: "0.875rem" }, component: FormHelperText }, { children: "From:" })), _jsx(Box, Object.assign({ sx: { width: "3.5em" } }, { children: _jsx(InputNumberFocusOut, { currVal: rangeMin, onUpdate: setRangeMin }) }))] })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsxs(Box, Object.assign({ sx: { display: "flex", gap: 1 }, alignItems: "center" }, { children: [_jsx(Typography, Object.assign({ sx: { fontSize: "0.875rem" }, component: FormHelperText }, { children: "To:" })), _jsx(Box, Object.assign({ sx: { width: "3.5em" } }, { children: _jsx(InputNumberFocusOut, { currVal: rangeMax, onUpdate: setRangeMax }) }))] })) }))] })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(Typography, Object.assign({ sx: { fontSize: "0.875rem" }, component: FormHelperText }, { children: "Colors:" })) })), _jsx(Grid, Object.assign({ item: true, xs: "auto" }, { children: _jsx(ColorRangeElements, { colors: colors, onUpdate: setColors }) }))] })));
}
export function SelectColorRageOptions({ bfactorColorRange, bfactorColors, disabled, callback, }) {
    return (_jsx(ParamOptionsMenu, Object.assign({ disabled: disabled }, { children: _jsx(SelectColorRagePopoverContent, { bfactorColorRange: bfactorColorRange, bfactorColors: bfactorColors, callback: callback }) })));
}
