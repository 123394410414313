// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
export var TableClickAction;
(function (TableClickAction) {
    TableClickAction["loadFile"] = "loadFile";
    TableClickAction["toggleVisibility"] = "toggleVisibility";
    TableClickAction["focusCoords"] = "focusCoords";
    TableClickAction["highlightSystem"] = "highlightSystem";
    TableClickAction["showSystemEntry"] = "showSystemEntry";
})(TableClickAction || (TableClickAction = {}));
export var HighlightSelectionType;
(function (HighlightSelectionType) {
    HighlightSelectionType["index"] = "index";
})(HighlightSelectionType || (HighlightSelectionType = {}));
export var TableRowButtonAction;
(function (TableRowButtonAction) {
    TableRowButtonAction["displayImage"] = "displayImage";
    TableRowButtonAction["loadSystem"] = "loadSystem";
})(TableRowButtonAction || (TableRowButtonAction = {}));
export var TableCheckboxIcon;
(function (TableCheckboxIcon) {
    TableCheckboxIcon["heart"] = "heart";
    TableCheckboxIcon["bookmark"] = "bookmark";
    TableCheckboxIcon["square"] = "square";
    TableCheckboxIcon["star"] = "star";
})(TableCheckboxIcon || (TableCheckboxIcon = {}));
