import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useState } from "react";
import { Popover, IconButton, Tooltip } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
function OpenParamOptionsBtn({ disabled, handleClick, }) {
    return (_jsx(Tooltip, Object.assign({ title: "More options", arrow: true }, { children: _jsx(IconButton, Object.assign({ disabled: disabled, onClick: handleClick, color: "primary", sx: {
                mb: "0.1rem",
            } }, { children: _jsx(AddCircleOutlineIcon, { sx: { fontSize: 20 } }) })) })));
}
export function ParamOptionsMenu({ children, disabled, }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "color-range-popover" : undefined;
    return (_jsxs("div", { children: [_jsx(OpenParamOptionsBtn, { disabled: disabled, handleClick: handleClick }), _jsx(Popover, Object.assign({ id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                }, sx: {
                    "& .MuiPaper-root": { borderRadius: "9px", p: 1 },
                    "& .react-colorful__pointer": {
                        height: "20px",
                        width: "20px",
                    },
                    "& .react-colorful": {
                        height: "180px",
                        width: "200px",
                    },
                    "& .react-colorful__hue": {
                        height: "20px",
                    },
                    "& input": {
                        // border: "none",
                        border: "1px solid #e6e6e6",
                        // width: "100px",
                        mt: 1,
                        p: "5px",
                        "input:focus": {
                            border: "none",
                        },
                    },
                } }, { children: children }))] }));
}
