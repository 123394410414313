import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, IconButton } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import { TableClickAction, TableRowButtonAction, } from "../utils";
import { NAPGenericStore } from "../GenericViewerState";
import { getAbsPathFromTable } from "../3dViewer/Controls/utils";
import { TooltipControlled } from "../utils/TooltipControlled";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { showSystemsEntry } from "./tableOnClickAction";
import { NAPTableStore } from "../StateStores";
import { dispatchNotificationEvent } from "../NotificationSystem";
import { setVisibilityAndFocus } from "./onRowClick";
import { useState } from "react";
function displayImageFromTable(cell, column, localBasePath) {
    const row = cell.row;
    const loadRowCol = Object.keys(row.original).find((rowCol) => rowCol.toLowerCase() === column.toLowerCase());
    if (!loadRowCol)
        return;
    const fileName = row.original[loadRowCol];
    const absPath = getAbsPathFromTable(fileName, localBasePath);
    NAPGenericStore.getState().setContentPanelLocalFilePath(absPath);
    NAPGenericStore.getState().setOpenFileContentPanel(true);
}
export function TableButtonCell({ pyodide, vss, molstar, cell, action, columns, tooltip, localBasePath, options, }) {
    let icon = action === TableRowButtonAction.loadSystem ? (_jsx(PlayCircleOutlineIcon, { fontSize: "small" })) : (_jsx(BarChartIcon, { fontSize: "small" }));
    const [disabled, setDisabled] = useState(false);
    const handleClick = (event) => {
        event.stopPropagation();
        try {
            switch (action) {
                case TableRowButtonAction.displayImage:
                    if (!localBasePath) {
                        dispatchNotificationEvent({
                            message: "Cannot access local file system.",
                            type: "error",
                        });
                        return;
                    }
                    displayImageFromTable(cell, columns[0], localBasePath);
                    break;
                case TableRowButtonAction.loadSystem:
                    if (!localBasePath) {
                        dispatchNotificationEvent({
                            message: "Cannot access local file system.",
                            type: "error",
                        });
                        return;
                    }
                    const actionconf = {
                        action: TableClickAction.loadFile,
                        column: columns,
                        options: options,
                    };
                    const navigationMode = NAPTableStore.getState().navigationMode;
                    setDisabled(true);
                    showSystemsEntry(vss, molstar, pyodide, cell.row, actionconf, undefined, localBasePath, true, navigationMode).then(({ cellRefs }) => {
                        if (cellRefs && cellRefs.length > 0) {
                            setVisibilityAndFocus(cellRefs, true, molstar, vss.getState().loaded_structures, vss, navigationMode);
                        }
                        setDisabled(false);
                    });
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.error(error);
            dispatchNotificationEvent({
                message: `Error loading system: ${error}`,
                type: "error",
            });
        }
    };
    const button = (_jsx(IconButton, Object.assign({ size: "small", "aria-label": "delete", onClick: handleClick, disabled: disabled }, { children: icon })));
    return (_jsxs(Box, Object.assign({ sx: {
            position: "relative",
            top: "-5px",
            left: "-15px",
        } }, { children: [tooltip ? (_jsx(TooltipControlled, Object.assign({ title: tooltip, arrow: true, placement: "top", sx: {
                    top: "-5px",
                    left: "-15px",
                } }, { children: button }))) : (_jsx(_Fragment, { children: button })), " "] })));
}
export function generateCellButton(pyodide, vss, molstar, cell, tableRowButtons, localBasePath) {
    return (_jsxs(Box, Object.assign({ sx: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
        } }, { children: [tableRowButtons.map((button) => (_jsx(TableButtonCell, { pyodide: pyodide, vss: vss, molstar: molstar, cell: cell, action: button.action, columns: button.column, tooltip: button.tooltip, options: button.options, localBasePath: localBasePath }))), " "] })));
}
