var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { loadTables } from "../Tables/loadTables";
import { syncTableToSystems } from "../Tables/utils";
import { loadPlots } from "./plots";
import { generateFileConfiguration, supportedFiles, } from "../";
import { loadSystems } from "./loadSystems";
import { displayNotSupportedError, displayUnmatchedError, } from "./FileReaders/errorMessageFormatting";
import { loadingInstructionsFromFiles } from "./loadingInstructionsFromFile";
import { findConfigFile } from "./utils/utils";
import { decompressStateExport } from "./FileReaders/loadZipFile";
import { loadMSAs } from "../MSA/loadMSA/loadMSA";
/**
 * Entrypoint of the file loading pipeline.
 * Detects if configuration file(s) are paseed and triggers appropriate actions.
 */
export const fileUploadingHandler = (e, vss, molstar, pyodide, NAPGenericStore, callback) => { var _a, e_1, _b, _c; return __awaiter(void 0, void 0, void 0, function* () {
    const target = e.target;
    const acceptedFiles = target.files;
    const compressedFormat = supportedFiles.compressed[0];
    const zipFiles = Array.from(acceptedFiles).filter((file) => file.name.endsWith(compressedFormat));
    const noZipFiles = Array.from(acceptedFiles).filter((file) => !file.name.endsWith(compressedFormat));
    let systemsLoaded = false;
    try {
        for (var _d = true, zipFiles_1 = __asyncValues(zipFiles), zipFiles_1_1; zipFiles_1_1 = yield zipFiles_1.next(), _a = zipFiles_1_1.done, !_a;) {
            _c = zipFiles_1_1.value;
            _d = false;
            try {
                const zipFile = _c;
                const acceptedFilesObject = yield decompressStateExport(zipFile);
                if (!acceptedFilesObject)
                    continue;
                const systemsLoadedZip = yield loadFileList(acceptedFilesObject, vss, molstar, pyodide, NAPGenericStore, true);
                if (systemsLoadedZip)
                    systemsLoaded = true;
            }
            finally {
                _d = true;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (!_d && !_a && (_b = zipFiles_1.return)) yield _b.call(zipFiles_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (noZipFiles.length > 0) {
        const systemsLoadedNoZip = yield loadFileList(noZipFiles, vss, molstar, pyodide, NAPGenericStore);
        if (systemsLoadedNoZip)
            systemsLoaded = true;
    }
    callback(systemsLoaded);
}); };
export function loadFileList(acceptedFiles, vss, molstar, pyodide, NAPGenericStore, isZip) {
    var _a, e_2, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const configFilesFound = findConfigFile(acceptedFiles);
        let _acceptedFiles = acceptedFiles instanceof FileList
            ? Array.from(acceptedFiles)
            : acceptedFiles;
        const acceptedFilesObject = Object.assign({}, ..._acceptedFiles.map((x) => ({
            [x["path"] ? x["path"] : x.name]: x,
        })));
        let instructionsSet = [];
        if (configFilesFound.length === 0) {
            const config = generateFileConfiguration(acceptedFiles);
            if (config.unsupportedFilesDetected.length > 0)
                displayNotSupportedError(config.unsupportedFilesDetected);
            if (config.unmatchedFiles.length > 0) {
                displayUnmatchedError(config.unmatchedFiles);
            }
            instructionsSet = [{ instructions: config.loadingInstructions }];
        }
        else {
            instructionsSet = yield loadingInstructionsFromFiles(configFilesFound, acceptedFilesObject, isZip);
        }
        let systemsLoaded = false;
        try {
            for (var _d = true, instructionsSet_1 = __asyncValues(instructionsSet), instructionsSet_1_1; instructionsSet_1_1 = yield instructionsSet_1.next(), _a = instructionsSet_1_1.done, !_a;) {
                _c = instructionsSet_1_1.value;
                _d = false;
                try {
                    const instructionsElement = _c;
                    const { instructions, localBasePath } = instructionsElement;
                    yield loadAllFileTypes(acceptedFilesObject, instructions, vss, molstar, pyodide, NAPGenericStore, localBasePath);
                    if (instructions.systems && instructions.systems.length > 0)
                        systemsLoaded = true;
                }
                finally {
                    _d = true;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (!_d && !_a && (_b = instructionsSet_1.return)) yield _b.call(instructionsSet_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return systemsLoaded;
    });
}
/**
 * Loads all file types specified in the given loading instructions and adds
 * successfully rendered systems to the VSS state.
 */
export function loadAllFileTypes(acceptedFiles, loadingInstructions, vss, molstar, pyodide, NAPGenericStore, localBasePath, quiet, archivedOnLoad) {
    return __awaiter(this, void 0, void 0, function* () {
        const s = yield loadSystems(acceptedFiles, loadingInstructions, vss, molstar, pyodide, quiet, archivedOnLoad);
        if (loadingInstructions.tables)
            yield loadTables(pyodide, vss, molstar, loadingInstructions.tables, NAPGenericStore, acceptedFiles, localBasePath, quiet, archivedOnLoad);
        if (s)
            syncTableToSystems(s);
        if (loadingInstructions.MSAs)
            yield loadMSAs(pyodide, vss, loadingInstructions.MSAs, NAPGenericStore, acceptedFiles, localBasePath);
        if (loadingInstructions.plots)
            yield loadPlots(pyodide, acceptedFiles, loadingInstructions.plots, NAPGenericStore, localBasePath, quiet, archivedOnLoad);
    });
}
