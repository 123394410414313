// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { findSystemFileInTree, setSystemCategoryDataViz } from "./utils";
import { loadDCFileFromTable, loadLocalFileFromTable, } from "./LoadFileFromTable/LoadFileFromTable";
export function findOrLoadSystemFromTable(vss, molstar, pyodide, topology, trajectory, representations, systemName, DCpath, localBasePath) {
    return __awaiter(this, void 0, void 0, function* () {
        //Could me unified with findOrLoadSystem
        const s = vss.getState().loaded_structures;
        let system;
        let loadedNewFile = false;
        if (DCpath) {
            system = yield loadDCFileFromTable(vss, molstar, pyodide, DCpath, s, topology, trajectory, representations);
        }
        else if (localBasePath) {
            // load from LFS
            ({ system, loadedNewFile } = yield loadLocalFileFromTable(vss, molstar, pyodide, localBasePath, s, topology, trajectory, systemName, representations));
        }
        else {
            system = findSystemFileInTree(s, topology);
            if (system)
                setSystemCategoryDataViz(system, vss);
        }
        return { system, loadedNewFile };
    });
}
