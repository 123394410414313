import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, ListItemIcon, ListItemText, MenuItem, Tooltip, } from "@mui/material";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { useState } from "react";
import { ColorPickerPopover } from "../../../3dViewer/RepresentationControls/SelectColor";
import { RoundActionIconButton } from "../../../components/Buttons/ActionIconButton/RoundActionIconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
export function AssignColorMenuItem({ setTableColor, handleCloseParent, tableColor, }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [tmpColor, setTmpColor] = useState(tableColor ? tableColor : "#ffffff");
    const open = Boolean(anchorEl);
    const id = open ? "table-color-picker-popover" : undefined;
    const confirmOrCancel = (_jsxs(Box, Object.assign({ display: "flex", flexDirection: "row", gap: 1, justifyContent: "flex-end", sx: { width: "100%" } }, { children: [_jsx(RoundActionIconButton, Object.assign({ size: "small", tooltipMsg: "Clear color", onClick: () => {
                    setTableColor(undefined);
                    handleClose();
                    handleCloseParent();
                }, paletteCategory: "error", colorOnHover: true }, { children: _jsx(CloseIcon, { fontSize: "small" }) })), _jsx(RoundActionIconButton, Object.assign({ size: "small", tooltipMsg: "Accept", onClick: () => {
                    setTableColor(tmpColor);
                    handleClose();
                    handleCloseParent();
                }, colorOnHover: true }, { children: _jsx(CheckIcon, { fontSize: "small" }) }))] })));
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: "Assign a color to the table, visible when more than one table is loaded.", placement: "right", arrow: true }, { children: _jsxs(MenuItem, Object.assign({ onClick: (event) => handleClick(event) }, { children: [_jsx(ListItemIcon, { children: _jsx(ColorLensIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Assign color" })] })) })), _jsx(ColorPickerPopover, { open: open, anchorEl: anchorEl, handleClose: () => {
                    handleClose();
                    handleCloseParent();
                }, color: tmpColor, onColorChange: setTmpColor, setDefaultButton: false, extraOptions: confirmOrCancel, id: id })] }));
}
