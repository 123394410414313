var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
//
import { StateObjectRef } from "molstar/lib/mol-state";
import { PluginStateObject } from "molstar/lib/mol-plugin-state/objects";
// dirty import, would prefer it passed as function arg
import { NAPGenericStore } from "../GenericViewerState/SharedState";
import { TrajectoryFromMoleculeKit } from "./moleculeToCIF";
import { createMolstarRepresentationObject } from "./RepresentationControls/AtomSelectionUtils";
export function getModelFromTrajectory(molstar, trajCellRef) {
    const subtreeStructures = molstar === null || molstar === void 0 ? void 0 : molstar.state.data.selectQ((q) => q.byRef(trajCellRef).subtree().ofType(PluginStateObject.Molecule.Structure));
    if (!subtreeStructures || subtreeStructures.length === 0) {
        return;
    }
    const strucCell = subtreeStructures.slice(-1).pop();
    return strucCell;
}
const _default_representations = {
    protein: {
        name: "Protein",
        selection: "protein or resname ACE NME",
        type: "cartoon",
        color: "secondary-structure",
        alpha: 1,
        refs: [],
    },
    nucleic: {
        name: "Nucleic",
        selection: "nucleic",
        type: "cartoon",
        color: "chain-id",
        alpha: 1,
        refs: [],
    },
    water: {
        name: "Water",
        selection: "water",
        type: "ball-and-stick",
        alpha: 1,
        color: "element-symbol",
        carbon_color_value: undefined,
        carbon_color_scheme: undefined,
        bfactor_color_range: undefined,
        bfactor_colors: undefined,
        refs: [],
    },
    membrane: {
        name: "Lipids",
        selection: "lipid",
        type: "ball-and-stick",
        alpha: 1,
        color: "element-symbol",
        carbon_color_value: undefined,
        carbon_color_scheme: undefined,
        bfactor_color_range: undefined,
        bfactor_colors: undefined,
        refs: [],
    },
    ions: {
        name: "Ions",
        selection: "ion",
        type: "ball-and-stick",
        alpha: 1,
        color: "element-symbol",
        carbon_color_value: undefined,
        carbon_color_scheme: undefined,
        bfactor_color_range: undefined,
        bfactor_colors: undefined,
        refs: [],
    },
    ligand: {
        name: "Ligand",
        selection: "not (protein or nucleic or water or ion or lipid or resname ACE NME)",
        type: "ball-and-stick",
        color: "element-symbol",
        carbon_color_value: undefined,
        carbon_color_scheme: undefined,
        bfactor_color_range: undefined,
        bfactor_colors: undefined,
        alpha: 1,
        refs: [],
    },
    all: {
        name: "All",
        selection: "all",
        type: "line",
        color: "element-symbol",
        carbon_color_value: undefined,
        carbon_color_scheme: undefined,
        bfactor_color_range: undefined,
        bfactor_colors: undefined,
        alpha: 1,
        refs: [],
    },
    small_polymer: {
        name: "Small polymer",
        selection: "protein or nucleic or resname ACE NME",
        type: "ball-and-stick",
        color: "element-symbol",
        carbon_color_value: undefined,
        carbon_color_scheme: undefined,
        bfactor_color_range: undefined,
        bfactor_colors: undefined,
        alpha: 1,
        refs: [],
    },
};
export function createModelAndStructure(molstar, mol, name) {
    return __awaiter(this, void 0, void 0, function* () {
        const trajectory = yield molstar.state.data
            .build()
            .toRoot()
            .apply(TrajectoryFromMoleculeKit, { mol: mol, name: name }, {})
            .commit();
        const model = yield molstar.builders.structure.createModel(trajectory);
        const structure = yield molstar.builders.structure.createStructure(model, {
            name: "model",
            params: {},
        });
        return structure;
    });
}
export function addRepresentation(molstar, pyodideWorker, 
// system: System,
singleResidue = false, moleculeID, isSdf, mol = undefined, cellRefs, visibility, customRepParams, aromaticBonds, sdfEntries) {
    var _a, customRepParams_1, customRepParams_1_1;
    var _b, e_1, _c, _d, _e, e_2, _f, _g, _h, e_3, _j, _k, _l, e_4, _m, _o;
    return __awaiter(this, void 0, void 0, function* () {
        const addedReps = [];
        const _cellRefs = cellRefs ? cellRefs : [];
        const _visibility = visibility === undefined ? true : visibility;
        if (customRepParams) {
            try {
                // Recreating existing representations
                for (_a = true, customRepParams_1 = __asyncValues(customRepParams); customRepParams_1_1 = yield customRepParams_1.next(), _b = customRepParams_1_1.done, !_b;) {
                    _d = customRepParams_1_1.value;
                    _a = false;
                    try {
                        const myrep = _d;
                        const repVisibility = myrep.visibility === undefined ? true : myrep.visibility;
                        let repParams = Object.assign(Object.assign({}, myrep), { visibility: _visibility && repVisibility, carbon_color_value: myrep.carbon_color_value, carbon_color_scheme: myrep.carbon_color_scheme, bfactor_color_range: myrep.bfactor_color_range, bfactor_colors: myrep.bfactor_colors });
                        let refs = [];
                        try {
                            for (var _p = true, _q = (e_2 = void 0, __asyncValues(_cellRefs.entries())), _r; _r = yield _q.next(), _e = _r.done, !_e;) {
                                _g = _r.value;
                                _p = false;
                                try {
                                    const [idx, cellRef] = _g;
                                    if (myrep.refs &&
                                        myrep.refs[idx] &&
                                        myrep.refs[idx].repRef === undefined) {
                                        refs.push({ cellRef: cellRef, repRef: undefined });
                                        continue;
                                    }
                                    if (myrep.color_value) {
                                        repParams.color_value = myrep.color_value;
                                    }
                                    try {
                                        const rep = yield createMolstarRepresentationObject(molstar, pyodideWorker, cellRef, moleculeID, repParams, isSdf, sdfEntries);
                                        refs.push({ cellRef: cellRef, repRef: rep.repRef });
                                    }
                                    catch (e) {
                                        NAPGenericStore.getState().setLoadingDCFile(false);
                                        console.error(e);
                                    }
                                }
                                finally {
                                    _p = true;
                                }
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (!_p && !_e && (_f = _q.return)) yield _f.call(_q);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        if (refs.some((r) => r.repRef !== undefined)) {
                            repParams.refs = refs;
                            repParams.visibility = repVisibility;
                            addedReps.push(repParams);
                        }
                    }
                    finally {
                        _a = true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_a && !_b && (_c = customRepParams_1.return)) yield _c.call(customRepParams_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return addedReps;
        }
        try {
            for (var _s = true, _t = __asyncValues(_cellRefs.entries()), _u; _u = yield _t.next(), _h = _u.done, !_h;) {
                _k = _u.value;
                _s = false;
                try {
                    const [idx, cellRef] = _k;
                    const structure = StateObjectRef.resolveAndCheck(molstar.state.data, cellRef);
                    if (!structure)
                        console.error(`No corresponding structure for cellRef ${cellRef}`);
                    if (!singleResidue && mol) {
                        const filteredReps = Object.keys(_default_representations).filter((e) => e !== "all" && e !== "small_polymer");
                        try {
                            for (var _v = true, filteredReps_1 = (e_4 = void 0, __asyncValues(filteredReps)), filteredReps_1_1; filteredReps_1_1 = yield filteredReps_1.next(), _l = filteredReps_1_1.done, !_l;) {
                                _o = filteredReps_1_1.value;
                                _v = false;
                                try {
                                    const myrepKey = _o;
                                    let rep = _default_representations[myrepKey];
                                    if ((myrepKey === "protein" &&
                                        mol.num_protein_res > 0 &&
                                        mol.num_protein_res < 6) ||
                                        (myrepKey === "nucleic" &&
                                            mol.num_nucleic_res > 0 &&
                                            mol.num_nucleic_res < 6)) {
                                        rep = _default_representations["small_polymer"];
                                    }
                                    yield createDefaultRep(molstar, aromaticBonds, pyodideWorker, rep, idx, addedReps, moleculeID, isSdf, cellRef, _visibility, sdfEntries);
                                }
                                finally {
                                    _v = true;
                                }
                            }
                        }
                        catch (e_4_1) { e_4 = { error: e_4_1 }; }
                        finally {
                            try {
                                if (!_v && !_l && (_m = filteredReps_1.return)) yield _m.call(filteredReps_1);
                            }
                            finally { if (e_4) throw e_4.error; }
                        }
                    }
                    else {
                        let rep = _default_representations["all"];
                        yield createDefaultRep(molstar, aromaticBonds, pyodideWorker, rep, idx, addedReps, moleculeID, isSdf, cellRef, _visibility, sdfEntries);
                    }
                }
                finally {
                    _s = true;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (!_s && !_h && (_j = _t.return)) yield _j.call(_t);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return addedReps;
    });
}
function createDefaultRep(molstar, aromaticBonds, pyodideWorker, myrep, idx, addedReps, moleculeID, isSdf, cellRef, visibility, sdfEntries) {
    return __awaiter(this, void 0, void 0, function* () {
        let repParams = Object.assign(Object.assign({}, myrep), { selection: myrep.selection, visibility: visibility, aromaticBonds: aromaticBonds });
        try {
            const rep = yield createMolstarRepresentationObject(molstar, pyodideWorker, cellRef, moleculeID, repParams, isSdf, sdfEntries);
            repParams = Object.assign(Object.assign({}, repParams), { visibility: true, refs: [{ cellRef: cellRef, repRef: rep.repRef }] });
            if (idx === 0) {
                addedReps.push(repParams);
            }
            else {
                for (const addedRep of addedReps) {
                    if (!addedRep.refs)
                        addedRep.refs = [];
                    addedRep.refs.push({
                        cellRef: cellRef,
                        repRef: rep.repRef,
                    });
                }
            }
            return true;
        }
        catch (e) {
            NAPGenericStore.getState().setLoadingDCFile(false);
            console.error(e);
            return false;
        }
    });
}
