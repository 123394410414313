// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { NAPTableStore } from "../StateStores/tables.store";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, dispatchNotificationEvent, } from "../NotificationSystem/utils";
import { formatTable } from "./prepareTable";
import uuidv4 from "../utils/UUID";
import { readCsv } from "./readCsv";
/* Just reads the file content. */
export function handleCSVLoad(tableFile) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onabort = () => reject(`File reading was aborted for ${tableFile.name}`);
        reader.onerror = () => {
            reject(`File reading has failed for ${tableFile.name}`);
        };
        reader.onload = () => {
            // Do whatever you want with the file contents
            const stringFileContent = reader.result;
            resolve(stringFileContent);
        };
        reader.readAsText(tableFile);
    });
}
function getLocalBasePath(tableFilePath, acceptedFilesObject) {
    // When the table has not been loaded with a config file, the tableFilePath will be missing. Add it when available (file loaded from CL or UI in desktop mode).
    try {
        if (acceptedFilesObject && tableFilePath in acceptedFilesObject) {
            const tableFile = acceptedFilesObject[tableFilePath];
            if (tableFile["isInLFS"]) {
                return tableFile["name"].substring(0, tableFile["name"].lastIndexOf("/"));
            }
            if (tableFile["path"]) {
                return tableFile["path"].substring(0, tableFile["path"].lastIndexOf("/"));
            }
        }
    }
    catch (e) {
        console.error(e);
    }
}
function loadTable(pyodide, vss, molstar, tableData, acceptedFilesObject, localBasePath, quiet, archivedOnLoad) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const tableFilePath = tableData.path;
        let error;
        let tableFileName;
        if (!localBasePath) {
            localBasePath = getLocalBasePath(tableFilePath, acceptedFilesObject);
        }
        const tableArrData = yield readCsv(tableFilePath, pyodide, acceptedFilesObject, quiet, "_pmv_originalId");
        let tableRes = undefined;
        if (tableArrData &&
            tableArrData.tableArr &&
            tableArrData.tableArr.length > 0) {
            const { tableFileName: _tableFileName, tableArr, error: _error, tableDCPath, isRemote, } = tableArrData;
            error = _error;
            tableFileName = _tableFileName;
            if (!quiet)
                dispatchControlledNotification({
                    idx: tableFileName,
                    type: "info",
                    message: `Loading`,
                    fileName: tableFileName,
                });
            const oldRowButton = (_a = tableData.options) === null || _a === void 0 ? void 0 : _a.rowButton;
            let rowButtons = (_c = (_b = tableData.options) === null || _b === void 0 ? void 0 : _b.rowButtons) !== null && _c !== void 0 ? _c : [];
            if (oldRowButton) {
                // Convert legacy single-column format to array format
                const convertedButton = {
                    action: oldRowButton.action,
                    column: [oldRowButton.column],
                    tooltip: oldRowButton.tooltip,
                };
                rowButtons = [...rowButtons, convertedButton];
            }
            rowButtons = rowButtons.length > 0 ? rowButtons : undefined;
            const loadedTable = yield formatTable(pyodide, vss, molstar, tableArr, rowButtons, localBasePath, tableData.options);
            const tableOptions = tableData.options ? tableData.options : {};
            if (!tableOptions["ignoreColumns"]) {
                tableOptions["ignoreColumns"] = [];
            }
            tableOptions["ignoreColumns"].push("_pmv_originalId");
            if (loadedTable) {
                tableRes = Object.assign(Object.assign({}, loadedTable), { tableID: uuidv4(), name: tableData.name ? tableData.name : tableFileName, path: tableFileName, localBasePath: localBasePath, archived: archivedOnLoad, options: tableOptions });
                if (isRemote) {
                    tableRes["DCpath"] = tableDCPath;
                }
            }
            if (!quiet)
                dispatchDeleteControlledNotification({ idx: tableFileName });
        }
        if (!tableRes) {
            error = "Error loading the table.";
        }
        if (error) {
            dispatchNotificationEvent({
                type: "error",
                message: error,
            });
        }
        return tableRes;
    });
}
export function loadTables(pyodide, vss, molstar, tables, NAPGenericStore, acceptedFilesObject, localBasePath, quiet, archivedOnLoad) {
    var _a, tables_1, tables_1_1;
    var _b, e_1, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        let loadedTables = [];
        try {
            for (_a = true, tables_1 = __asyncValues(tables); tables_1_1 = yield tables_1.next(), _b = tables_1_1.done, !_b;) {
                _d = tables_1_1.value;
                _a = false;
                try {
                    let tableData = _d;
                    const tableConfig = yield loadTable(pyodide, vss, molstar, tableData, acceptedFilesObject, localBasePath, quiet, archivedOnLoad);
                    if (tableConfig) {
                        loadedTables.push(tableConfig);
                    }
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = tables_1.return)) yield _c.call(tables_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (loadedTables.length > 0) {
            NAPTableStore.getState().add_tables(loadedTables);
            if (!archivedOnLoad) {
                const activePanels = NAPGenericStore.getState()._activePanels;
                NAPGenericStore.getState().setActivePanels(Object.assign(Object.assign({}, activePanels), { plots: true }));
            }
        }
    });
}
