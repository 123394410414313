var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Panels } from "../GenericViewerState/SharedState";
import { supportedFiles } from "..";
import { NAPPlotStore, NAPTableStore } from "../StateStores";
import produce from "immer";
import { setSessionSystemParamsByFileName } from "./utils";
import { cameraFocus } from "../3dViewer/Controls/CameraControls/cameraFocus";
import { DataPortalStore } from "./dataportal.store";
import { loadTables } from "../Tables/loadTables";
import { loadPlots } from "../FileLoaders/plots";
import { loadSystemsDataPortal } from "./dataPortalSystemLoader";
function readFile(pyodide, filePath) {
    return __awaiter(this, void 0, void 0, function* () {
        const ext = filePath.split(".").pop();
        if (!ext)
            return;
        const encoding = supportedFiles.coordinates_trajectory.includes(ext)
            ? "binary"
            : "utf8";
        return yield pyodide.FS.readFile(filePath, encoding);
    });
}
function readSessionFile(pyodide, sessionFIlePath) {
    return __awaiter(this, void 0, void 0, function* () {
        const sessionStr = yield readFile(pyodide, sessionFIlePath);
        if (typeof sessionStr !== "string")
            return;
        return JSON.parse(sessionStr);
    });
}
function getSystemFiles(system) {
    let systFiles = [];
    if (system.children) {
        system.children.forEach((childSyst) => {
            const result = getSystemFiles(childSyst);
            systFiles.push(...result);
        });
    }
    else if (system.files) {
        systFiles = [
            {
                paths: system.files,
                visibility: system.visibility == undefined ? true : system.visibility,
            },
        ];
    }
    return systFiles;
}
function createCustomFileObject(pyodide, basePath, filePath, skipFileRead) {
    return __awaiter(this, void 0, void 0, function* () {
        const fullPath = `${basePath}/${filePath}`;
        const fileData = skipFileRead ? fullPath : yield readFile(pyodide, fullPath);
        // const stats = await pyodide.FS.stat(fullPath);
        return {
            name: filePath,
            size: 0,
            type: "application/octet-stream",
            lastModified: new Date(),
            data: fileData,
            isLoaded: !skipFileRead,
            isInVFS: !!skipFileRead,
        };
    });
}
function getFilePathsInSession(session) {
    return __awaiter(this, void 0, void 0, function* () {
        // find files in the nap file, read content, generate a fake file obj.
        const systemFiles = [];
        const tableFiles = [];
        const plotFiles = [];
        for (let elementType in session) {
            const elData = session[elementType];
            if (Array.isArray(elData)) {
                elData.forEach((systOrOther) => {
                    if (systOrOther.path) {
                        // it's a table or plot
                        const ext = systOrOther.path.split(".").pop();
                        const isTable = supportedFiles.tables.includes(ext);
                        if (isTable)
                            tableFiles.push(systOrOther.path);
                        else
                            plotFiles.push(systOrOther.path);
                    }
                    else {
                        // it's a system
                        const systFilePaths = getSystemFiles(systOrOther);
                        systemFiles.push(...systFilePaths);
                    }
                });
            }
        }
        return {
            tableFiles: tableFiles,
            plotFiles: plotFiles,
            systemFiles: systemFiles,
        };
    });
}
function showLoadedTables(filePaths) {
    //Checks if the set of files is already loaded as table. plot or system. If it is, sets it to visible.
    const tables = NAPTableStore.getState().tables;
    let activeTable;
    const updated_tables = produce(tables, (draftTables) => {
        for (let tableIdx = 0; tableIdx < draftTables.length; tableIdx++) {
            const table = draftTables[tableIdx];
            const tablePath = table.path;
            if (filePaths.includes(tablePath)) {
                table.archived = false;
                activeTable = tableIdx;
            }
            else {
                table.archived = true;
            }
        }
    });
    NAPTableStore.getState().set_tables(updated_tables);
    if (activeTable !== undefined)
        NAPTableStore.getState().set_activeTableIdx(activeTable);
}
function showLoadedPlots(filePaths) {
    //Checks if the set of files is already loaded as plot. plot or system. If it is, sets it to visible.
    const plots = NAPPlotStore.getState().plots;
    let activePlot;
    const updated_plots = produce(plots, (draftPlots) => {
        for (let plotIdx = 0; plotIdx < draftPlots.length; plotIdx++) {
            const plot = draftPlots[plotIdx];
            const plotName = plot.name;
            if (filePaths.includes(plotName)) {
                plot.archived = false;
                activePlot = plotIdx;
            }
            else {
                plot.archived = true;
            }
        }
    });
    NAPPlotStore.getState().set_plots(updated_plots);
    if (activePlot !== undefined)
        NAPPlotStore.getState().set_activePlotIdx(activePlot);
}
function showLoadedSystems(vss, loadedSystFiles, molstar) {
    const { isLoaded, cellRefs } = setSessionSystemParamsByFileName(vss, loadedSystFiles, molstar);
    if (isLoaded && cellRefs.length > 0)
        cameraFocus(molstar, cellRefs);
    return isLoaded;
}
function showLoadedFiles(vss, molstar, tableFiles, plotFiles, systemFiles) {
    showLoadedTables(tableFiles);
    showLoadedPlots(plotFiles);
    showLoadedSystems(vss, systemFiles, molstar);
    return;
}
export function displaySessionFiles(sessionRelPath, targetID, NAPGenericStore, vss, molstar) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!targetID)
            return;
        const targetSessions = DataPortalStore.getState().targetSessions;
        const { sessionFiles } = targetSessions[sessionRelPath];
        if (!sessionFiles)
            return;
        const tableFiles = sessionFiles.tableFiles;
        const plotFiles = sessionFiles.plotFiles;
        const systemFiles = sessionFiles.systemFiles;
        showLoadedFiles(vss, molstar, tableFiles, plotFiles, systemFiles);
        const activePanels = NAPGenericStore.getState()._activePanels;
        NAPGenericStore.getState().setActivePanels(Object.assign(Object.assign({}, activePanels), { [Panels.plots]: tableFiles.length > 0 || plotFiles.length > 0, [Panels.statetree]: systemFiles.length > 0 }));
    });
}
function loadSessionDataViz(basePath, tableFiles, plotFiles, sessionDataViz, pyodide, vss, molstar, NAPGenericStore, sessionRelPath) {
    var _a, e_1, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const filePaths = [...tableFiles, ...plotFiles];
        const fileData = [];
        try {
            for (var _d = true, filePaths_1 = __asyncValues(filePaths), filePaths_1_1; filePaths_1_1 = yield filePaths_1.next(), _a = filePaths_1_1.done, !_a;) {
                _c = filePaths_1_1.value;
                _d = false;
                try {
                    const filePath = _c;
                    const data = yield createCustomFileObject(pyodide, basePath, filePath);
                    fileData.push(data);
                }
                finally {
                    _d = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_d && !_a && (_b = filePaths_1.return)) yield _b.call(filePaths_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        const fileDataDict = Object.assign({}, ...Array.from(fileData).map((x) => ({
            [x.name]: x,
        })));
        if (sessionDataViz.tables)
            yield loadTables(pyodide, vss, molstar, sessionDataViz.tables, NAPGenericStore, fileDataDict, undefined, true, true);
        if (sessionDataViz.plots)
            yield loadPlots(pyodide, fileDataDict, sessionDataViz.plots, NAPGenericStore, undefined, true, true);
        let activeSession = DataPortalStore.getState().activeSession;
        if (activeSession === sessionRelPath) {
            showLoadedTables(tableFiles);
            showLoadedPlots(plotFiles);
        }
    });
}
function loadAllSessionsDataViz(targetSessionsFiles, targetSessionsConfig, pyodide, vss, molstar, NAPGenericStore) {
    var _a, e_2, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            for (var _d = true, _e = __asyncValues(Object.entries(targetSessionsFiles)), _f; _f = yield _e.next(), _a = _f.done, !_a;) {
                _c = _f.value;
                _d = false;
                try {
                    const [sessionRelPath, { sessionFiles, basePath },] = _c;
                    const session = targetSessionsConfig[sessionRelPath];
                    let { ["systems"]: systems } = session, sessionDataViz = __rest(session, ["systems"]);
                    yield loadSessionDataViz(basePath, sessionFiles.tableFiles, sessionFiles.plotFiles, sessionDataViz, pyodide, vss, molstar, NAPGenericStore, sessionRelPath);
                }
                finally {
                    _d = true;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (!_d && !_a && (_b = _e.return)) yield _b.call(_e);
            }
            finally { if (e_2) throw e_2.error; }
        }
    });
}
export function loadSessionSystems(basePath, systemFiles, sessionSystems, pyodide, vss, molstar, sessionRelPath, skipNotVisible) {
    var _a, e_3, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const filePaths = systemFiles
            .filter((item) => (skipNotVisible ? item.visibility : !item.visibility))
            .flatMap((item) => item.paths);
        const fileData = [];
        try {
            for (var _d = true, filePaths_2 = __asyncValues(filePaths), filePaths_2_1; filePaths_2_1 = yield filePaths_2.next(), _a = filePaths_2_1.done, !_a;) {
                _c = filePaths_2_1.value;
                _d = false;
                try {
                    const filePath = _c;
                    const data = yield createCustomFileObject(pyodide, basePath, filePath, true);
                    fileData.push(data);
                }
                finally {
                    _d = true;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (!_d && !_a && (_b = filePaths_2.return)) yield _b.call(filePaths_2);
            }
            finally { if (e_3) throw e_3.error; }
        }
        const fileDataDict = Object.assign({}, ...Array.from(fileData).map((x) => ({
            [x.name]: x,
        })));
        yield loadSystemsDataPortal(fileDataDict, sessionSystems, vss, molstar, pyodide, true, true, skipNotVisible, basePath);
        let activeSession = DataPortalStore.getState().activeSession;
        if (activeSession === sessionRelPath) {
            showLoadedSystems(vss, systemFiles, molstar);
        }
    });
}
function loadAllSessionsSystems(targetSessionsFiles, targetSessionsConfig, pyodide, vss, molstar) {
    var _a, e_4, _b, _c, _d, e_5, _e, _f;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            // 1st load only what is visible
            for (var _g = true, _h = __asyncValues(Object.entries(targetSessionsFiles)), _j; _j = yield _h.next(), _a = _j.done, !_a;) {
                _c = _j.value;
                _g = false;
                try {
                    const [sessionRelPath, { sessionFiles, basePath },] = _c;
                    const session = targetSessionsConfig[sessionRelPath];
                    let { ["systems"]: systems } = session, sessionDataViz = __rest(session, ["systems"]);
                    yield loadSessionSystems(basePath, sessionFiles.systemFiles, { systems: systems }, pyodide, vss, molstar, sessionRelPath, true);
                    DataPortalStore.getState().addCompletedSession(sessionRelPath);
                }
                finally {
                    _g = true;
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (!_g && !_a && (_b = _h.return)) yield _b.call(_h);
            }
            finally { if (e_4) throw e_4.error; }
        }
        try {
            // load the rest
            for (var _k = true, _l = __asyncValues(Object.entries(targetSessionsFiles)), _m; _m = yield _l.next(), _d = _m.done, !_d;) {
                _f = _m.value;
                _k = false;
                try {
                    const [sessionRelPath, { sessionFiles, basePath },] = _f;
                    const session = targetSessionsConfig[sessionRelPath];
                    let { ["systems"]: systems } = session, sessionDataViz = __rest(session, ["systems"]);
                    yield loadSessionSystems(basePath, sessionFiles.systemFiles, { systems: systems }, pyodide, vss, molstar, sessionRelPath);
                }
                finally {
                    _k = true;
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (!_k && !_d && (_e = _l.return)) yield _e.call(_l);
            }
            finally { if (e_5) throw e_5.error; }
        }
    });
}
export function loadSessionFiles(sessions, targetID, resultsDir, pyodide, NAPGenericStore, vss, molstar) {
    var _a, sessions_1, sessions_1_1;
    var _b, e_6, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        const start = Date.now();
        if (!targetID)
            return;
        const targetFilePath = `${resultsDir}/${targetID.toUpperCase()}`;
        const targetSessionsFiles = {};
        const targetSessionsConfig = {};
        try {
            for (_a = true, sessions_1 = __asyncValues(sessions); sessions_1_1 = yield sessions_1.next(), _b = sessions_1_1.done, !_b;) {
                _d = sessions_1_1.value;
                _a = false;
                try {
                    const sessionRelPath = _d;
                    const sessionFilePath = `${targetFilePath}/${sessionRelPath}`;
                    const session = yield readSessionFile(pyodide, sessionFilePath);
                    const { tableFiles, plotFiles, systemFiles } = yield getFilePathsInSession(session);
                    const basePath = sessionFilePath.substring(0, sessionFilePath.lastIndexOf("/"));
                    targetSessionsConfig[sessionRelPath] = session;
                    targetSessionsFiles[sessionRelPath] = {
                        sessionFiles: {
                            tableFiles: tableFiles,
                            plotFiles: plotFiles,
                            systemFiles: systemFiles,
                        },
                        basePath: basePath,
                    };
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = sessions_1.return)) yield _c.call(sessions_1);
            }
            finally { if (e_6) throw e_6.error; }
        }
        DataPortalStore.getState().setTargetSessions(targetSessionsFiles);
        // Load plots and tables
        yield loadAllSessionsDataViz(targetSessionsFiles, targetSessionsConfig, pyodide, vss, molstar, NAPGenericStore);
        // Load systems
        yield loadAllSessionsSystems(targetSessionsFiles, targetSessionsConfig, pyodide, vss, molstar);
        console.log(`-------- ${(Date.now() - start) / 1000}`);
    });
}
