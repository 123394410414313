import { defaultColorRange } from "./representationParamOptions";
import { defaultCarbonColorScheme } from "./SelectColorOptions/SelectCarbonColor";
function defineUncertainityColorParams(bfactor_color_range, bfactor_colors) {
    const _bfactor_color_range_min = bfactor_color_range && bfactor_color_range.length > 0
        ? bfactor_color_range[0]
        : 0;
    const _bfactor_color_range_max = bfactor_color_range && bfactor_color_range.length > 1
        ? bfactor_color_range[1]
        : 100;
    const _bfactor_colors = bfactor_colors ? bfactor_colors : defaultColorRange;
    return {
        domain: [_bfactor_color_range_min, _bfactor_color_range_max],
        list: {
            colors: _bfactor_colors,
            // kin: "interpolate",
        },
    };
}
export function defineColorParams(color, color_value, carbon_color_value, carbon_color_scheme, bfactor_color_range, bfactor_colors) {
    const uniformColorAvailable = color === "uniform" && color_value ? color_value : undefined;
    const _carbon_color_scheme = carbon_color_scheme !== null && carbon_color_scheme !== void 0 ? carbon_color_scheme : defaultCarbonColorScheme;
    const carbolColorConf = color === "element-symbol"
        ? {
            name: _carbon_color_scheme,
            params: carbonColorParams[_carbon_color_scheme] || undefined,
        }
        : undefined;
    if (carbolColorConf && carbon_color_value) {
        carbolColorConf["params"] = { value: carbon_color_value };
    }
    const carbonColorAvailable = carbolColorConf !== undefined;
    const bFactorColorSettings = color === "uncertainty";
    const colorParams = uniformColorAvailable
        ? {
            value: uniformColorAvailable,
            carbonColor: undefined,
        }
        : carbonColorAvailable
            ? {
                carbonColor: carbolColorConf,
            }
            : bFactorColorSettings
                ? defineUncertainityColorParams(bfactor_color_range, bfactor_colors)
                : undefined;
    return colorParams;
}
const molstarPalette = {
    name: "colors",
    params: {
        list: {
            kind: "set",
            colors: [
                1810039, 14245634, 7696563, 15149450, 6727198, 15117058, 10909213,
                6710886, 14948892, 3636920, 5091146, 9981603, 16744192, 16777011,
                10901032, 16220607, 10066329, 6734501, 16551266, 9281739, 15174339,
                10934356, 16767279, 15058068, 11776947,
            ],
        },
    },
};
const carbonColorParams = {
    "chain-id": {
        asymId: "auth",
        palette: molstarPalette,
    },
    "entity-id": {
        palette: molstarPalette,
    },
    "model-index": {
        palette: molstarPalette,
    },
    "structure-index": {
        palette: molstarPalette,
    },
    "trajectory-index": {
        palette: molstarPalette,
    },
};
