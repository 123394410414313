var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PluginStateObject } from "molstar/lib/mol-plugin-state/objects";
import { StateObjectRef, StateSelection } from "molstar/lib/mol-state";
import { basicStructureQueries } from "../BasicStructureQueries";
import { dispatchNotificationEvent } from "../../NotificationSystem/utils";
// import { getStructureProperties, Molecule } from "./StructurePropsExtractor";
import { defineColorParams } from "./utils";
export function generateRepName(molstar, cellRef) {
    var _a;
    const defaultName = `${cellRef}_${0}`;
    if (!molstar)
        return defaultName;
    // Find last representation in the system, if any
    const lastRep = molstar.state.data
        .selectQ((q) => q
        .byRef(cellRef)
        .subtree()
        .ofType(PluginStateObject.Molecule.Structure.Representation3D))
        .pop();
    if (!lastRep)
        return defaultName;
    // Find the name of the parent structure
    const lastRepStruc = StateSelection.findAncestorOfType(molstar.state.data.tree, molstar.state.data.cells, lastRep.transform.ref, PluginStateObject.Molecule.Structure);
    const lastName = (_a = lastRepStruc === null || lastRepStruc === void 0 ? void 0 : lastRepStruc.transform.tags) === null || _a === void 0 ? void 0 : _a.pop();
    const lastNum = Number(lastName === null || lastName === void 0 ? void 0 : lastName.split("_").pop());
    return `${cellRef}_${lastNum || lastNum === 0 ? lastNum + 1 : 0}`;
}
const unFocusCell = (molstar, cellRef) => {
    var _a, _b;
    const focusedModelId = (_a = molstar.managers.structure.focus.current) === null || _a === void 0 ? void 0 : _a.loci.structure.model.id;
    if (!focusedModelId)
        return;
    const r = molstar.state.data.selectQ((q) => q.byRef(cellRef).parent());
    if (r && r.length > 0) {
        const editedModelId = (_b = r[0].obj) === null || _b === void 0 ? void 0 : _b.data.id;
        if (editedModelId === focusedModelId) {
            molstar.managers.structure.focus.clear();
        }
    }
};
export const createMolstarRepresentationObject = (molstar, pyodide, cellRef, // old set_molecule / molstar_struct_ref
moleculeID, params, isSdf, sdfEntries) => new Promise((resolve, reject) => __awaiter(void 0, void 0, void 0, function* () {
    const selectionQuery = params.selection;
    const visibility = params.visibility === undefined ? true : params.visibility;
    const type = params.type ? params.type : "line";
    const alpha = params.alpha === undefined ? 1 : params.alpha;
    const color = params.color ? params.color : "element-symbol";
    const aromaticBonds = params.aromaticBonds;
    const ignoreLight = params.ignoreLight;
    const color_value = params.color_value;
    const carbon_color_value = params.carbon_color_value;
    const carbon_color_scheme = params.carbon_color_scheme;
    const bfactor_color_range = params.bfactor_color_range;
    const bfactor_colors = params.bfactor_colors;
    unFocusCell(molstar, cellRef);
    const selection_arr = yield pyodide.RunPythonAsync({
        context: { moleculeID, selectionQuery, isSdf, sdfEntries },
        script: `
      from pyodide.ffi import to_js
      from js import moleculeID, selectionQuery, isSdf, sdfEntries
      
      to_js(selectMatchingAtoms(moleculeID, selectionQuery, isSdf, sdfEntries), depth=2)
    `,
    });
    if (selection_arr instanceof Map && selection_arr.get("error")) {
        reject(selection_arr.get("error"));
        dispatchNotificationEvent({
            message: selection_arr.get("error"),
            type: "error",
        });
        return;
    }
    if (selection_arr.length === 0) {
        reject(`Empty selection array!`);
        return;
    }
    const _q = basicStructureQueries.atoms(selection_arr);
    if (!_q) {
        reject(`Empty selection array!`);
        return;
    }
    const structureCell = StateObjectRef.resolveAndCheck(molstar.state.data, cellRef);
    if (!structureCell) {
        reject("Invalid selection.");
        return;
    }
    const repName = generateRepName(molstar, cellRef);
    const component = yield molstar.builders.structure.tryCreateComponentFromExpression(structureCell, _q, repName /* user-defined representation name*/);
    if (!component) {
        reject("Invalid selection query.");
        return;
    }
    const representation = yield molstar.builders.structure.representation.addRepresentation(component, {
        type: type,
        typeParams: {
            alpha: alpha,
            aromaticBonds: aromaticBonds,
            multipleBonds: "symmetric",
            ignoreLight: ignoreLight,
        },
        color: color,
        colorParams: defineColorParams(color, color_value, carbon_color_value, carbon_color_scheme, bfactor_color_range, bfactor_colors),
    }, {
        initialState: {
            isHidden: !visibility,
        },
    });
    // if (!get().visibility) setVisibility(molstar, [representation.ref], true);
    const x = {
        cellRef: cellRef,
        repRef: representation.ref,
        query: selectionQuery,
    };
    resolve(x);
}));
