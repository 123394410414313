// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NAPGenericStore } from "../GenericViewerState";
import { tableOnClickAction } from "./tableOnClickAction";
import { setVisibility } from "../3dViewer/VisibilityToggle";
import { updateStateTreeParameterFromCellRefs, updateStateTreeVisibilityFromCellRefs, } from "../3dViewer/stateTree";
import { focusOnSystem } from "./utils";
export const onRowClick = (row, rowIsActive, activeTable, activeTableIdx, navigationMode, vss, molstar, pyodide, openSystemsPanel) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const rowActions = (_a = activeTable === null || activeTable === void 0 ? void 0 : activeTable.options) === null || _a === void 0 ? void 0 : _a.onRowClick;
    let cellRefs;
    let visibility = true;
    if (rowActions) {
        if (NAPGenericStore.getState().loadingDCFile)
            return;
        const setLoadingDCFile = NAPGenericStore.getState().setLoadingDCFile;
        setLoadingDCFile(true);
        ({ cellRefs, visibility } = yield tableOnClickAction(vss, molstar, pyodide, rowActions, row, activeTableIdx, openSystemsPanel, activeTable.DCpath, activeTable.localBasePath, rowIsActive, navigationMode));
        setLoadingDCFile(false);
    }
    else {
        const rowCellRefs = row.original.cellRefs;
        if (rowCellRefs && rowCellRefs.length > 0)
            cellRefs = [rowCellRefs];
    }
    if (!cellRefs || cellRefs.length === 0)
        return;
    const systems = vss.getState().loaded_structures;
    setVisibilityAndFocus(cellRefs, visibility, molstar, systems, vss, navigationMode);
});
export function setVisibilityAndFocus(cellRefs, visibility, molstar, systems, vss, navigationMode) {
    cellRefs.forEach((systCellRefs) => {
        setVisibility(molstar, systCellRefs, visibility);
        updateStateTreeVisibilityFromCellRefs(vss, systCellRefs, visibility, molstar);
        if (visibility)
            focusOnSystem(molstar, systCellRefs, systems);
        if (navigationMode)
            updateStateTreeParameterFromCellRefs(vss, systCellRefs, {
                archived: !visibility,
            });
    });
}
