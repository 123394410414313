import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ParamOptionsMenu } from "../../Controls/ParamOptionsMenu";
import { Box } from "@mui/material";
import { SelectionField } from "../SelectRepresentationParam";
import { useEffect, useState } from "react";
import { hexToNum, numToHex } from "../../Controls/utilsColors";
import ColorPickerPopoverButton from "../SelectColor";
export function SelectCarbonColor({ currCarbonColorScheme, activeColor, onColorPickerUpdate, disabled, onCarbonColorSchemeUpdate, }) {
    const [carbonColorScheme, setCarbonColorScheme] = useState(currCarbonColorScheme !== null && currCarbonColorScheme !== void 0 ? currCarbonColorScheme : defaultCarbonColorScheme);
    useEffect(() => {
        setCarbonColorScheme(currCarbonColorScheme !== null && currCarbonColorScheme !== void 0 ? currCarbonColorScheme : defaultCarbonColorScheme);
    }, [currCarbonColorScheme]);
    useEffect(() => {
        if (carbonColorScheme !== currCarbonColorScheme) {
            onCarbonColorSchemeUpdate({ carbon_color_scheme: carbonColorScheme });
        }
    }, [carbonColorScheme]);
    const activeOrDefColor = activeColor !== null && activeColor !== void 0 ? activeColor : 16777215;
    const [color, setColor] = useState(numToHex(activeOrDefColor));
    useEffect(() => {
        setColor(numToHex(activeOrDefColor));
    }, [activeOrDefColor]);
    useEffect(() => {
        const colorNum = hexToNum(color);
        if (colorNum !== activeColor) {
            onColorPickerUpdate(colorNum);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [color]);
    return (_jsx(ParamOptionsMenu, Object.assign({ disabled: disabled }, { children: _jsxs(Box, Object.assign({ sx: { display: "flex", flexDirection: "row", alignItems: "center" } }, { children: [_jsx(SelectionField, { currValue: carbonColorScheme, menuLabel: "Carbon Color", options: options, callback: (e) => setCarbonColorScheme(e.target.value), disabled: disabled }), carbonColorScheme === "uniform" ? (_jsx(Box, Object.assign({ sx: { pt: 2, pl: 1, m: "3px" } }, { children: _jsx(Box, Object.assign({ sx: {
                            display: "flex",
                            "& .MuiPaper-root": { p: 0 },
                            maxWidth: "172px",
                            width: "100%",
                        }, justifyContent: "center", alignItems: "center" }, { children: _jsx(ColorPickerPopoverButton, { color: color, onColorChange: setColor, setDefaultButton: false, disabled: disabled }) })) }))) : null] })) })));
}
export const defaultCarbonColorScheme = "chain-id";
const options = [
    { label: "Chain ID", value: "chain-id" },
    { label: "Custom Color", value: "uniform" },
    // { label: "Element", value: "element-symbol" },
    { label: "Entity Id", value: "entity-id" },
    { label: "Model Index", value: "model-index" },
    { label: "Structure Index", value: "structure-index" },
    { label: "Trajectory Index", value: "trajectory-index" },
];
