var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { createVolumeRepresentationParams } from "molstar/lib/mol-plugin-state/helpers/volume-representation-params";
import { Volume } from "molstar/lib/mol-model/volume";
import { StateObjectRef } from "molstar/lib/mol-state";
import { Box, FormControl, InputLabel, Select, MenuItem, } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { updateStateTreeVolume } from "./stateTree";
import { OpacitySlider } from "./RepresentationControls/OpacitySlider";
import { SelectColor } from "./RepresentationControls/SelectColor";
import { RepresentationOptionLabel } from "./RepresentationControls/RepresentationOptionLabel";
import PaletteIcon from "@mui/icons-material/Palette";
export function getVolumeParams(_molstar, cellRef) {
    var _a, _b;
    const selectedStruct = StateObjectRef.resolveAndCheck(_molstar === null || _molstar === void 0 ? void 0 : _molstar.state.data, cellRef);
    const volValues = (_a = selectedStruct === null || selectedStruct === void 0 ? void 0 : selectedStruct.params) === null || _a === void 0 ? void 0 : _a.values;
    return {
        visuals: volValues.type.params.visuals[0],
        alpha: volValues.type.params.alpha,
        color: volValues.colorTheme.params.value,
        isoValue: volValues.type.params.isoValue.absoluteValue,
        volumeData: (_b = selectedStruct === null || selectedStruct === void 0 ? void 0 : selectedStruct.obj) === null || _b === void 0 ? void 0 : _b.data.sourceData,
    };
}
function updateMolstarParams(_molstar, cellRef, repType, newValue) {
    return __awaiter(this, void 0, void 0, function* () {
        const volparams = getVolumeParams(_molstar, cellRef);
        if (!volparams)
            return;
        const { visuals, alpha, color, isoValue, volumeData } = volparams;
        yield _molstar
            .build()
            .to(cellRef)
            .update(createVolumeRepresentationParams(_molstar, volumeData, {
            typeParams: {
                visuals: repType === "Visualization" ? [newValue] : [visuals],
                alpha: repType === "Opacity" ? newValue : alpha,
                isoValue: Volume.adjustedIsoValue(volumeData, isoValue, "absolute"),
            },
            color: "uniform",
            colorParams: {
                value: repType === "Color" ? newValue : color,
            },
        }))
            .commit();
    });
}
export function replaceMolstarParams(_molstar, cellRef, newParams) {
    return __awaiter(this, void 0, void 0, function* () {
        const volparams = getVolumeParams(_molstar, cellRef);
        if (!volparams)
            return;
        const { isoValue, volumeData } = volparams;
        yield _molstar
            .build()
            .to(cellRef)
            .update(
        //@ts-ignore
        createVolumeRepresentationParams(_molstar, volumeData, {
            typeParams: {
                visuals: newParams.type ? [newParams.type] : [defaultVizType],
                alpha: newParams.alpha ? newParams.alpha : defaultAlpha,
                isoValue: Volume.adjustedIsoValue(volumeData, isoValue, "absolute"),
            },
            color: "uniform",
            colorParams: {
                value: newParams.color ? newParams.color : defaultColor,
            },
        }))
            .commit();
    });
}
export function SelectVolumeVisualization({ vss, molstar, activeSystem, type, NAPGenericStore, }) {
    const _type = type ? type : defaultVizType;
    const [reprElement, setReprElement] = useState(_type);
    useEffect(() => {
        if (_type != reprElement)
            setReprElement(_type);
    }, [_type]);
    const cellRef = activeSystem.cellRef ? activeSystem.cellRef[0] : "";
    const [activeSystemPosition] = NAPGenericStore((state) => [state._activeSystemPosition], shallow);
    const options = volumeVisualizationOptions;
    const callback = (e) => __awaiter(this, void 0, void 0, function* () {
        if (!activeSystemPosition)
            throw Error("Undefined active system position");
        yield updateMolstarParams(molstar, cellRef, "Visualization", e.target.value);
        updateStateTreeVolume(vss, activeSystemPosition, "type", e.target.value);
    });
    return (_jsx(SelectionField, { currValue: reprElement, menuLabel: _jsx(RepresentationOptionLabel, { isLabel: true, icon: _jsx(AutoAwesomeIcon, {}), text: "Visualization" }), options: options, callback: callback }));
}
export function SelectVolumeColor({ vss, molstar, activeSystem, color, NAPGenericStore, }) {
    const _color = color ? color : defaultColor;
    const cellRef = activeSystem.cellRef ? activeSystem.cellRef[0] : "";
    const [activeSystemPosition] = NAPGenericStore((state) => [state._activeSystemPosition], shallow);
    const callback = (newColor) => __awaiter(this, void 0, void 0, function* () {
        if (!activeSystemPosition)
            throw Error("Undefined active system position");
        yield updateMolstarParams(molstar, cellRef, "Color", newColor ? newColor : 13421772);
        updateStateTreeVolume(vss, activeSystemPosition, "color", newColor);
    });
    return (_jsxs(Box, Object.assign({ sx: { pl: "11px", display: "flex" }, gap: 1, justifyContent: "flex-start", alignItems: "center" }, { children: [_jsx(RepresentationOptionLabel, { icon: _jsx(PaletteIcon, {}), text: "Color", textVariant: "body2" }), _jsx(SelectColor, { activeColor: _color, callback: callback })] })));
}
export function SliderVolumeRepresentationParam({ vss, molstar, activeSystem, alpha, repType, NAPGenericStore, }) {
    const _apha = alpha ? alpha : defaultAlpha;
    const cellRef = activeSystem.cellRef ? activeSystem.cellRef[0] : "";
    const [activeSystemPosition] = NAPGenericStore((state) => [state._activeSystemPosition], shallow);
    const [reprElement, setReprElement] = useState(_apha);
    useEffect(() => {
        if (_apha !== reprElement) {
            setReprElement(_apha);
        }
    }, [_apha]);
    const callback = (e) => __awaiter(this, void 0, void 0, function* () {
        if (!activeSystemPosition)
            throw Error("Undefined active system position");
        yield updateMolstarParams(molstar, cellRef, repType, e);
        updateStateTreeVolume(vss, activeSystemPosition, "alpha", e);
    });
    const defaultval = typeof reprElement === "number" ? reprElement : 1;
    return _jsx(OpacitySlider, { currVal: defaultval, onUpdate: callback });
}
function SelectionField({ currValue, menuLabel, options, callback, }) {
    return (_jsx(Box, Object.assign({ sx: { minWidth: 100, marginTop: 2 } }, { children: _jsxs(FormControl, Object.assign({ fullWidth: true }, { children: [_jsx(InputLabel, { children: menuLabel }), _jsx(Select, Object.assign({ size: "small", value: currValue, label: menuLabel, onChange: (e) => callback(e), sx: {
                        "& .MuiSelect-select": { fontSize: "0.875rem" },
                    }, inputProps: {
                        style: {
                            fontSize: "0.875rem",
                        },
                    }, MenuProps: {
                        PaperProps: {
                            sx: {
                                "& .MuiMenuItem-root": {
                                    fontSize: "0.875rem",
                                },
                            },
                        },
                    } }, { children: options.map((option) => (_jsx(MenuItem, Object.assign({ sx: { fontSize: "0.875rem" }, value: option.value }, { children: option.label }), option.value))) }))] })) })));
}
const volumeVisualizationOptions = [
    {
        label: "Solid",
        value: "solid",
    },
    {
        label: "Wireframe",
        value: "wireframe",
    },
];
const defaultVizType = "wireframe";
const defaultColor = 16737792;
const defaultAlpha = 0.4;
