import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import { representationColorOptions } from "./representationParamOptions";
import { Grid } from "@mui/material";
import { SelectionField } from "./SelectRepresentationParam";
import { SelectColor } from "./SelectColor";
import PaletteIcon from "@mui/icons-material/Palette";
import { RepresentationOptionLabel } from "./RepresentationOptionLabel";
import { SelectColorRageOptions } from "./SelectColorOptions/SelectColorRange";
import { SelectCarbonColor } from "./SelectColorOptions/SelectCarbonColor";
export function RepresentationColorSelector({ onSchemeSelection, onColorPickerUpdate, onColorRangeUpdate, onCarbonColorSchemeUpdate, currColorScheme, currCarbonColorScheme, currColorVal, currCarbonColorVal, bfactorColorRange, bfactorColors, disabled, }) {
    const [reprColorScheme, setReprColorScheme] = useState(currColorScheme);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [displayColorRageOptions, setDisplayColorRageOptions] = useState(false);
    const [displayCarbonColorOptions, setDisplayCarbonColorOptions] = useState(false);
    useEffect(() => {
        setReprColorScheme(currColorScheme);
        setDisplayColorPicker(currColorScheme === "uniform");
        setDisplayCarbonColorOptions(currColorScheme === "element-symbol");
        setDisplayColorRageOptions(currColorScheme === "uncertainty");
    }, [currColorScheme]);
    useEffect(() => {
        onSchemeSelection(reprColorScheme);
        setDisplayColorPicker(reprColorScheme === "uniform");
        setDisplayCarbonColorOptions(reprColorScheme === "element-symbol");
        setDisplayColorRageOptions(reprColorScheme === "uncertainty");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reprColorScheme]);
    const handleSelection = (e) => {
        const newVal = e.target.value;
        setReprColorScheme(newVal);
    };
    const _onColorPickerUpdate = (color) => {
        onColorPickerUpdate(color, currColorScheme === "element-symbol"
            ? "carbon_color_value"
            : "color_value");
    };
    const menuLabel = (_jsx(RepresentationOptionLabel, { icon: _jsx(PaletteIcon, {}), text: "Color Scheme", isLabel: true }));
    const options = representationColorOptions;
    const activeColor = currColorScheme === "uniform"
        ? currColorVal
            ? currColorVal
            : 13421772
        : currCarbonColorVal;
    return (_jsxs(Grid, Object.assign({ container: true, alignItems: "center", direction: "row" }, { children: [_jsx(Grid, Object.assign({ item: true, xs: true }, { children: _jsx(SelectionField, { currValue: reprColorScheme, menuLabel: menuLabel, options: options, callback: handleSelection, disabled: disabled }) })), displayColorPicker ? (_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { pt: 2, pl: 2 } }, { children: _jsx(SelectColor, { activeColor: activeColor, callback: _onColorPickerUpdate, 
                    // setDefaultButton={currColorScheme === "element-symbol"}
                    disabled: disabled }) }))) : null, displayColorRageOptions && (_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { pt: 2 } }, { children: _jsx(SelectColorRageOptions, { bfactorColorRange: bfactorColorRange, bfactorColors: bfactorColors, disabled: disabled, callback: onColorRangeUpdate }) }))), displayCarbonColorOptions && (_jsx(Grid, Object.assign({ item: true, xs: "auto", sx: { pt: 2 } }, { children: _jsx(SelectCarbonColor, { currCarbonColorScheme: currCarbonColorScheme, activeColor: activeColor, onColorPickerUpdate: _onColorPickerUpdate, disabled: disabled, onCarbonColorSchemeUpdate: onCarbonColorSchemeUpdate }) })))] })));
}
